import React from "react";
import { Link } from "react-router-dom";

const MaleTeacher = () => {
  return (
    <div className="coursesPage">
      {/* Hero Section */}
      <div className="hero-section">
        <h1>Our Male Teachers</h1>
        <p>
          Discover the foundational principles of the Quran with the guidance of
          our dedicated male Quran teachers.
        </p>

        <div className="breadcrumb-container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link href="/">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link href="/maleteacher">Teachers</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Male Teacher
              </li>
            </ol>
          </nav>
        </div>
      </div>

      {/* Breadcrumb */}

      <div className="courseDisplay">
        <div className="course-image">
          <img className="img-fluid" src="img/teacher.png" alt="" />
        </div>
        <div className="courseContent">
          <h2>About Male Quran Teacher</h2>
          <p>
            At Al Mehdi Online Quran Academy, Discover the foundational
            principles of the Quran with the guidance of our dedicated male
            Quran teachers. Our interactive and immersive courses are designed
            to make learning both meaningful and enjoyable. With personalized
            instruction and a focus on practical application, you’ll gain a deep
            understanding of Quranic teachings while developing your tajweed
            skills. Join us to embark on a transformative learning journey and
            deepen your connection with the Quran.
          </p>
        </div>
      </div>

      <div className="card">
        <h2>Teacher Qualifications</h2>
        <p>Our Quran teachers are:</p>
        <ul>
          <li>
            <i className="fas fa-arrow-right"></i> Qari-e-Quran (Have completed
            Qira't course)
          </li>
          <li>
            <i className="fas fa-arrow-right"></i> Hafiz-e-Quran (Have memorized
            the whole Quran)
          </li>
          <li>
            <i className="fas fa-arrow-right"></i> Well aware of Rules of
            Tajweed
          </li>
          <li>
            <i className="fas fa-arrow-right"></i> University graduates in
            Islamic Studies
          </li>
          <li>
            <i className="fas fa-arrow-right"></i> Well trained to teach Quran
            online
          </li>
          <li>
            <i className="fas fa-arrow-right"></i> Have excellent command of
            English, Arabic, and Urdu languages to deliver lectures
          </li>
          <li>
            <i className="fas fa-arrow-right"></i> Highly motivated and
            understand the complexities faced in this profession
          </li>
        </ul>
      </div>

      <div className="card">
        <h2>Our Teachers' Skills</h2>
        <p>We have expert teachers in different disciplines:</p>
        <ul>
          <li>
            <i className="fas fa-arrow-right"></i> Quranic Qaida teachers
          </li>
          <li>
            <i className="fas fa-arrow-right"></i> Quran with Tajweed teachers
          </li>
          <li>
            <i className="fas fa-arrow-right"></i> Quran Memorization teachers
          </li>
          <li>
            <i className="fas fa-arrow-right"></i> Quran Translation teachers
          </li>
          <li>
            <i className="fas fa-arrow-right"></i> Islamic Studies teachers
          </li>
        </ul>
      </div>

      {/* What you will Learn */}
      <section className="courses">
        <h2>What will student learn from our Online Quran Teacher?</h2>

        <div className="coursescontainer">
          <ul>
            <li>
              <i className="fas fa-star"></i>
              <Link href="noraniQaida">Basic Noorani Qaida Course</Link>
            </li>
            <li>
              <i className="fas fa-star"></i>
              <Link href="tajweed">Rules of tajweed Course</Link>
            </li>
            <li>
              <i className="fas fa-star"></i>
              <Link href="Translation">Quran Translation Course</Link>
            </li>
            <li>
              <i className="fas fa-star"></i>
              <Link href="tafseer">Quran Tafseer Course</Link>
            </li>
            <li>
              <i className="fas fa-star"></i>
              <Link href="hifz">Quran Memorisation Courses</Link>
            </li>
            <li>
              <i className="fas fa-star"></i>
              <Link href="islamicstudy">Islamic Studies for kids Course</Link>
            </li>
          </ul>
          <p>
            These courses are designed in the way that a student can learn and
            understand Quran directly with live teachers. Choose the one that
            best suits you and your kids and get equipped with the learning of
            Quran from professional and qualified Quran tutors.
          </p>
          <p>Build up a life long relationship with Quran.</p>
          <button>Book a Free Trial</button>
        </div>
      </section>

      <section className="card">
        <h2>Quran teacher for kids and adults</h2>
        <p>
          Teaching the Quran is a profound responsibility that demands
          dedication and precision. At Al Mehdi Online Quran Academy, we
          recognize the effort and attention required to ensure that every
          lesson adheres to the rules of Tajweed. Our teachers understand the
          weight of their role in delivering high-quality Quranic education and
          are committed to meeting this responsibility with utmost care.
        </p>
        <p>
          A truly effective Quran teacher must be both qualified and
          experienced, able to adapt to the diverse learning styles of students.
          At Al Mehdi Online Quran Academy, our teachers excel in engaging with
          both children and adults, tailoring their approach to meet individual
          needs. They are adept at teaching with patience and empathy, ensuring
          that each student grasps the Quranic principles effectively.
        </p>
        <p>
          Historically, Quranic education was imparted by tutors or Qaris in
          Mosques or at home, starting with basic Noorani Qaida classes.
          However, with the advancement of technology and the rise of the
          internet, learning methods have evolved. Today, students have the
          option to learn the Quran online, from the comfort of their homes.
          Online Quran tutoring offers unparalleled convenience and flexibility,
          making it an ideal choice for modern learners.
        </p>
        <p>
          Choosing an online Quran tutor from Al Mehdi Online Quran Academy
          ensures access to expert instruction and personalized support, fitting
          seamlessly into today’s busy lifestyles.
        </p>
      </section>

      {/* Why Choose Us */}
      <div className="card">
        <h2>Quran teacher for all Nationalities</h2>
        <p>
          Certainly! Here’s a text incorporating the international reach of Al
          Mehdi Online Quran Academy and emphasizing the quality of your online
          Quran teachers: At Al Mehdi Online Quran Academy, we proudly welcome
          students from across the globe, embracing diverse races and cultural
          backgrounds. Our dedicated Quran teachers have made a significant
          impact, helping a large number of students benefit from their
          expertise. Our online Quran teachers serve students in a wide range of
          countries, including the USA, Canada, Australia, the UK, Germany,
          Norway, Ireland, Switzerland, France, Italy, Iceland, Sweden, Denmark,
          Finland, Greece, Singapore, Hong Kong, Belgium, Spain, Oman, Qatar,
          Bahrain, Kuwait, Saudi Arabia, and the UAE. We are also continuously
          welcoming students from new regions around the world. We are confident
          that our team of professional and expert Quran teachers is the best
          choice for learning the Quran online. With their dedication and skill,
          you will receive high-quality instruction tailored to your needs, no
          matter where you are. Equip yourself with the knowledge and
          understanding of the Quran from our esteemed online teachers. Join Al
          Mehdi Online Quran Academy and experience exceptional Quranic
          education from anywhere in the world.
        </p>
      </div>
    </div>
  );
};

export default MaleTeacher;
